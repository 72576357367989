html {
  scroll-behavior: smooth;
  font-size: 8px;
}

html,
body {
  max-width: 100vw;
  height: 100%;
  overflow-x: hidden;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {
  font-size: 1.75rem;
}

a {
  color: inherit;
  text-decoration: none;
}
